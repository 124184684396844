<template>
    <div v-if="journal">
        <div class="card-header"><h5>{{translation.authors}}</h5></div>
        <div class="row mx-0 mb-1 mt-1 row-cols-2" v-if="journal.authors.length">
            <div class="col" v-for="(author, idx) in journal.authors" :key="`author-${idx}`">
                <div class="card author-card mt-3">
                    <div class="row">
                        <div class="col-md-2 mt-3 ml-3 justify-content-center">
                            <img src="../assets/images/author_icon.svg" class="card-img mx-auto d-block" :alt="author.fullName">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <router-link :to="author.name" append>
                                    <h5 class="card-title">{{author.fullName}}</h5>
                                </router-link>
                                
                                <p class="card-text"><small class="text-muted">{{author.institutions[0]}}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="!loadingJournalData" class="row justify-content-center mt-2 mb-2">
            <div class="col col-md-3">
                <p>{{translation.noSearchResult}}</p>
            </div>
        </div>
        <div class="row justify-content-center mt-2 mb-2">
            <div class="col col-md-3">
                <button class="btn btn-info" v-if="!loadingJournalData && journal.authorsSearchHasMore" @click="$store.dispatch('loadMoreJournalAuthors', journal.issn)">{{translation.loadMore}}</button>
                <div v-if="loadingJournalData" class="col col-md-3">
                    <div class="spinner-border text-primary" role="status"></div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['journal'],
    data: () => ({
        loading: true
    }),
    computed: {
        ... mapGetters(['loadingJournalData'])
    }
}
</script>

<style lang="scss" scoped>
.author-card {
    img {
        margin-top: 5px;
        width: 50px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    height: 200px !important;
    overflow: hidden;
    p {
        text-overflow: ellipsis;
    }
}
em.search-hightlight {
    background-color: yellow;
}
</style>