<template>
    <div>
        <div class="card-header">
            <button v-if="isChild" type="button" class="btn btn-info" @click="$router.back()">
                <font-awesome-icon icon="chevron-circle-left" />
                {{translation.back}}
            </button>
            <h5>{{title || translation.articles}}</h5>
            <slot>
            </slot>
        </div>
        <ArticleCard :article="article" v-for="article, idx in searchResult.items" :key="`article-item-${idx}`" />
        <div class="row justify-content-center mt-2 mb-2" v-if="!loadingArticles && searchResult.items.length && hasMoreResults">
            <div class="col col-md-3">
                <button class="btn btn-info" @click="fetchMoreResults" >
                    {{translation.loadMore}}
                </button>
                <div v-if="loadingArticles" class="spinner-border ml-3 text-secondary spinner-border-sm" role="status"></div>
            </div>
        </div>
        <div v-if="loadingArticles" class="row justify-content-center mt-2 mb-2">
            <div class="col col-md-3">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
        </div>
        <div v-else-if="!searchResult.items.length && !hasMoreResults" class="row justify-content-center mt-2 mb-2">
            <div class="col col-md-3">
                <p>{{translation.noSearchResult}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import ArticleCard from '../components/ArticleCard'
import { mapGetters } from 'vuex'

export default {
    components: {ArticleCard},
    props: ['journal', 'isChild', 'title'],
    computed: {
        ...mapGetters(['searchResult', 'loadingArticles']),
        hasMoreResults() {
            return this.$store.state.search.hasMoreSearchResults
        }
    },
    methods: {
        fetchMoreResults() {
            this.$store.dispatch('fetchMoreSearchResults')
        }
    }
}
</script>

<style lang="scss">
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    * {
        display: inline;
    }
}
h5 {
    margin-bottom: 0;
}
</style>